import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import 'react-multi-carousel/lib/styles.css'
import Slider from 'react-slick'
import { formataDataCosmic } from '@monorepo-amais/commons/utils/date'
import imgixUrlOptimizerAuto from '../../utils/imgixUrlOptimizerAuto'

const SidebarDiv = styled.div`
	border-radius: 3px;
	border: 1px solid ${props => props.theme.colors.graye2};
	padding: 20px 10px;

	@media (max-width: 767.98px) {
		border: none;
		padding: 20px 0px;
	}
`
const SidebarTitle = styled.p`
	margin: 0;
	font-size: 18px;
	color: #464646;
	font-weight: bold;

	@media (max-width: 767.98px) {
		width: 207px;
		height: 30px;
		font-size: 28px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.32;
		letter-spacing: normal;
		text-align: left;
		color: #242221;
	}
`
const SidebarList = styled.ul`
	padding: 0;
	@media (max-width: 767.98px) {
		width: 100%;

		.slick-slide {
			> div {
				margin: 0 5px;
				border: 1px solid ${props => props.theme.colors.graye2};
			}
		}
		.slick-arrow {
			display: none !important;
		}
	}
	@media (min-width: 992px) {
		.slick-slide {
			width: 100% !important;
		}
	}
	@media (min-width: 768px) and (max-width: 991.98px) {
		.slick-slide {
			width: 100% !important;
		}
		.slick-track {
			width: 100% !important;
		}
	}
`
const SidebarItem = styled.li`
	list-style: none;

	@media (max-width: 767.98px) {
		min-height: 214px;
	}
`
const Image = styled.img`
	width: 100%;
	height: 180px;
	object-fit: cover;
`
const settings = {
	className: 'center',
	centerMode: false,
	infinite: false,
	slidesToShow: 0,
	speed: 500,
	centerPadding: '20px',
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 4,
				slidesToScroll: 4,
				centerMode: true,
				infinite: true,
				centerPadding: 25
			}
		},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				initialSlide: 1,
				centerMode: true,
				infinite: true,
				centerPadding: 25
			}
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				initialSlide: 1,
				centerMode: true,
				infinite: true,
				centerPadding: 25
			}
		}
	]
}
const Title = styled.p`
	margin: 0;
	position: relative;
	font-size: 16px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.32;
	letter-spacing: normal;
	text-align: left;
	color: ${props => props.theme.colors.black_fonts};
	&:after {
		content: '';
		position: absolute;
		bottom: -10px;
		left: 0;
		width: 90px;
		height: 5px;
		background: ${props => props.theme.colors.primary};
		@media (max-width: 767.98px) {
			height: 4px;
			left: 10px;
		}
	}
	@media (max-width: 991.98px) {
		font-size: 14px;
	}

	@media (max-width: 767.98px) {
		padding: 8px;
	}
`
const Date = styled.p`
	color: #707070;
	font-size: 14px;

	@media (max-width: 767.98px) {
		padding: 8px;
		// width: 124px;
		height: 16px;
		font-size: 14px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.14;
		letter-spacing: normal;
		text-align: left;
		color: #707070;
	}
`

const CardLink = styled(Link)`
	text-decoration: none;
`

const BtnAll = styled(Link)`
	color: ${props => props.theme.colors.primary};
	text-decoration: none;
	display: block;
	margin: 0 auto;
	text-align: center;
	font-weight: bold;
	border-top: 1px solid #e2e2e2;
	padding-top: 20px;
	font-size: 20px;
	@media (min-width: 768px) and (max-width: 991.98px) {
		font-size: 16px;
	}

	@media (max-width: 767.98px) {
		display: none;
	}
`

const Separator = styled.div`
	@media (max-width: 767.98px) {
		display: none;
	}
	display: ${props => (props.last === true ? 'none' : 'block')};
	border: solid 0.5px #e2e2e2;
	width: 90%;
	margin: 0 0 20px 5%;
`

const SidebarNoticias = ({ allCosmicData }) => {
	const [isRendered, setIsRendered] = useState(false)

	const cards = allCosmicData.nodes.slice(0, 4).map((ultimasnoticias, index) => {
		const linkTo = `/noticias/${ultimasnoticias.slug}`

		return (
			<CardLink key={ultimasnoticias.slug} to={linkTo}>
				<SidebarItem key={ultimasnoticias.slug}>
					<Image
						src={imgixUrlOptimizerAuto(ultimasnoticias.metadata.thumbnail.imgix_url)}
						alt={ultimasnoticias.title}
					/>
					<Title id={`title-last-news-card-${index}`}>{ultimasnoticias.title}</Title>
					<Date>{formataDataCosmic(ultimasnoticias.metadata.data)}</Date>
				</SidebarItem>
				<Separator last={index === (allCosmicData.nodes.length >= 4 ? 3 : allCosmicData.nodes.length - 1)}></Separator>
			</CardLink>
		)
	})

	//  O useEffect abaixo foi implementado devido um bug do Gatsby com Slider (react-slick)
	useEffect(() => {
		setTimeout(() => {
			setIsRendered(true)
		}, 200)
	}, [])

	return (
		<SidebarDiv>
			<SidebarTitle>Últimas Notícias</SidebarTitle>
			<SidebarList>{isRendered && <Slider {...settings}>{cards}</Slider>}</SidebarList>
			<BtnAll to='/noticias'>Ver todas as notícias</BtnAll>
		</SidebarDiv>
	)
}

export default SidebarNoticias
