import React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import SidebarNoticias from '@monorepo-amais/commons/components/Noticias/SidebarNoticias'
import DetalhesNoticia from '@monorepo-amais/commons/components/Noticias/DetalhesNoticia'
import Calendar from '@monorepo-amais/commons/assets/img/calendar.png'
import BreadcrumbAuto from '@monorepo-amais/commons/components/Blocks/BreadcrumbAuto'
import SEO from '../components/seo'

const Container = styled.div`
	padding: 30px 0px 0px;
	width: 85%;
	margin: 0 auto;
	@media (max-width: 767.98px) {
		width: 92%;
	}
	@media (min-width: 768px) and (max-width: 1199.98px) {
		width: 90%;
	}
`
const Grid = styled.div`
	display: grid;
	grid-template-areas: 'l l l l l l l l l r r r';
	grid-gap: 3%;
	.left {
		grid-area: l;
	}
	.right {
		grid-area: r;
	}
	justify-content: space-between;
	padding: 20px 0;
	@media (max-width: 991.98px) {
		display: flex;
		flex-wrap: wrap;
	}
`
const ColLeft = styled.div`
	@media (max-width: 767.98px) {
		width: 100%;
	}
`
const ColRight = styled.div`
	@media (max-width: 767.98px) {
		width: 100%;
	}
`
const PageDetalhesNoticias = ({ data }) => {
	const seoTitle = `${data.cosmicjsNoticias.title} › Notícias`

	data.cosmicData = data.cosmicjsNoticias
	data.allCosmicData = data.allCosmicjsNoticias

	return (
		<Layout footer={data.footer} regionais={data.regionais}>
			<SEO title={seoTitle} />
			<Container>
				<BreadcrumbAuto title={data.cosmicjsNoticias.title} />
				<Grid>
					<ColLeft className='left'>
						<DetalhesNoticia {...data} Calendar={Calendar} />
					</ColLeft>
					<ColRight className='right'>
						<SidebarNoticias {...data} />
					</ColRight>
				</Grid>
			</Container>
		</Layout>
	)
}

export default PageDetalhesNoticias
export const query = graphql`
	query($id: String!) {
		cosmicjsNoticias(slug: { eq: $id }) {
			title
			content
			slug
			id
			metadata {
				data
				pequena_descricao
				seo {
					description
					imageAlt
					title
					image {
						imgix_url
						url
					}
				}
				thumbnail {
					imgix_url
					url
				}
			}
		}
		allCosmicjsNoticias {
			nodes {
				id
				metadata {
					pequena_descricao
					seo {
						description
						image {
							imgix_url
							url
						}
						imageAlt
						title
					}
					thumbnail {
						imgix_url
						url
					}
					data
				}
				title
				slug
			}
		}
	}
`
