/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import { FacebookSquare } from 'styled-icons/fa-brands/FacebookSquare'
import { Linkedin } from 'styled-icons/fa-brands/Linkedin'
import { TwitterSquare } from 'styled-icons/fa-brands/TwitterSquare'
import { WhatsappSquare } from 'styled-icons/fa-brands/WhatsappSquare'
import { formataDataCosmic, formatDateString } from '@monorepo-amais/commons/utils/date'
import useCreateElement from '../../hooks/useCreateElement'
import { testPageStatus } from '../../utils/region'
import imgixUrlOptimizerAuto from '../../utils/imgixUrlOptimizerAuto'

const Container = styled.div`
	display: grid;
	grid-template-areas:
		't t t t t t t t t t t t'
		'l l l l l l l r r r r r'
		'd d d d d d d d d d d d'
		'c c c c c c c c c c c c';
	.left {
		grid-area: l;
	}
	.right {
		grid-area: r;
	}
	.title {
		grid-area: t;
	}
	.divider {
		grid-area: d;
	}
	.calendar {
		grid-area: c;
	}

	border: solid 1px ${props => props.theme.colors.graye2};
	padding: 40px 20px 0;
	flex-wrap: wrap;
	@media (max-width: 991.98px) {
		padding: 0;

		grid-template-areas:
			'r r r r r r r r r r r r'
			't t t t t t t t t t t t'
			'l l l l l l l l l l l l'
			'd d d d d d d d d d d d'
			'c c c c c c c c c c c c';
	}
	@media (min-width: 768px) and (max-width: 1199.98px) {
		padding: 40px 10px 0;
	}
`

const Subtitle = styled.h3`
	margin: 0;
	position: relative;
	font-size: 26px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.32;
	letter-spacing: normal;
	text-align: left;
	color: ${props => props.theme.colors.black_fonts};
	&:after {
		content: '';
		position: absolute;
		bottom: -10px;
		left: 0;
		width: 90px;
		height: 5px;
		background: ${props => props.theme.colors.primary};
		@media (max-width: 767.98px) {
			height: 4px;
			left: 10px;
		}
	}
	@media (max-width: 767.98px) {
		font-size: 22px;
		order: 2;
		padding: 5px 10px;
	}
`
const Text = styled.div`
	padding-top: 40px;
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.31;
	letter-spacing: normal;
	text-align: left;
	color: ${props => props.theme.colors.gray66};
	margin-block-start: 0;
	margin-block-end: 0;
	img {
		max-width: fit-content;
		height: auto;
	}
	@media (max-width: 991.98px) {
		line-height: 1.4;
		font-size: 15px;
		padding-top: 20px;
	}
`

const Image = styled.img`
	max-width: 300px;
	width: 100%;
	@media (max-width: 991.98px) {
		margin: 0 auto;
		display: block;
		max-width: 400px;
	}
`

const TextWrapper = styled.div`
	display: flex;
	color: ${theme('colors.gray66')};
	padding: 0 20px 0 0;
	@media (max-width: 767.98px) {
		order: 3;
		padding: 0 10px;
	}
`

const SecaoImage = styled.div`
	display: block;
	padding-top: 40px;
	@media (max-width: 767.98px) {
		margin: 0;
		order: 1;
		padding-top: 0;
	}
`
const Icon = styled.img``

const Hr = styled.hr`
	background-color: ${props => props.theme.colors.graye2};
	height: 2px;
	border: 0;
	width: 100%;
	@media (max-width: 767.98px) {
		width: 100%;
	}
`

const Data = styled.p`
	font-size: 18px;
	padding-left: 10px;
	@media (max-width: 767.98px) {
		font-size: 14px;
	}
	@media (max-width: 1023.98px) {
		display: none;
	}
`

const DataMobile = styled.p`
	font-size: 18px;
	padding-left: 10px;
	@media (max-width: 767.98px) {
		font-size: 14px;
	}
	@media (min-width: 1024px) {
		display: none;
	}
`

const Compart = styled.p`
	font-size: 18px;
	@media (max-width: 767.98px) {
		font-size: 12px;
	}
`

const DivDataCom = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	@media (max-width: 767.98px) {
		display: inline-flex;
		padding: 0 10px;
	}
`

const CalendarDiv = styled.div``

const ShareDiv = styled.div`
	text-align: right;
`

const IconH2 = styled.div`
	display: flex;
	align-items: center;
	@media (max-width: 767.98px) {
		margin-right: 20px;
	}
`

const CompIcon = styled.div`
	align-items: center;
	display: flex;

	@media (max-width: 767.98px) {
		display: block;
	}
`

const Icons = styled.div`
	display: flex;

	@media (max-width: 767.98px) {
		margin: 0 0 12px;
	}
`

const WhatsappWeb = styled.a`
	@media (max-width: 1023.98px) {
		display: none;
	}
`

const WhatsappMobile = styled.a`
	@media (min-width: 1023.99px) {
		display: none;
	}
`

const Facebook = styled(FacebookSquare)`
	width: 25px;
	height: 25px;
	color: #707070;

	margin: 0px 0px 0px 10px;
`

const LinkedinIco = styled(Linkedin)`
	width: 25px;
	height: 25px;
	color: #707070;

	margin: 0px 0px 0px 10px;
`

const Twitter = styled(TwitterSquare)`
	width: 25px;
	height: 25px;
	color: #707070;

	margin: 0px 0px 0px 10px;
`

const Whatsapp = styled(WhatsappSquare)`
	width: 25px;
	height: 25px;
	color: #707070;

	margin: 0px 0px 0px 10px;
`

const IconDiv = styled.div`
	margin: 3px 5px;
	border-radius: 2px;
	width: 25px;
	height: 25px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	@media (max-width: 767.98px) {
		margin: 3px 4px;
	}
`

const Compartilhar = styled.div`
	display: flex;

	@media (max-width: 767.98px) {
		display: block;
	}
`

export default function DetalhesNoticia({ cosmicData, Calendar }) {
	const [, setOptions] = useCreateElement({
		rel: 'canonical',
		type: 'link'
	})
	useEffect(() => {
		const addCanonicalAfterTest = async () => {
			const href = window.location.origin + '/sp' + window.location.pathname.substr(3)
			const pageExistOnAmais = await testPageStatus(href)
			if (pageExistOnAmais)
				setOptions({
					href
				})
		}
		if (
			typeof window !== 'undefined' &&
			process.env.COSMIC_BUCKET.match(/^amais/g) &&
			!window.location.pathname.match(/\/sp/)
		) {
			addCanonicalAfterTest()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	const ref = useRef(null)
	const { slug, title, content, metadata } = cosmicData
	let shareLink = ''

	if (typeof window !== 'undefined') {
		shareLink = window.location ? window.location.href : ''
	}

	const shareText = `${encodeURIComponent(slug)}%0A%0A${encodeURIComponent(
		slug
	)}%0A%0AConfira o material completo em:%0A%0A${shareLink}`

	const facebookBody = `https://www.facebook.com/sharer/sharer.php?u=${shareLink}`
	const linkedinBody = `https://www.linkedin.com/shareArticle/?mini=true&url=${shareLink}`
	const twitterBody = `https://twitter.com/intent/tweet?text=${shareText}`
	const whatsWebBody = `https://web.whatsapp.com/send?text=${shareText}`
	const whatsMobileBody = `https://api.whatsapp.com/send?text=${shareText}`

	useEffect(() => {
		if (ref && ref.current) {
			const imgs = ref.current.getElementsByTagName('img') || []
			for (let img of imgs) {
				img.style.width = '100%'
			}
		}
	}, [])

	return (
		<Container>
			{/* <Row> */}
			<Subtitle className='title'>{title}</Subtitle>
			<TextWrapper className='left'>
				<Text ref={ref} dangerouslySetInnerHTML={{ __html: content }} />
			</TextWrapper>
			<SecaoImage className='right'>
				<Image
					src={imgixUrlOptimizerAuto(metadata.thumbnail.imgix_url)}
					alt='Atendimento móvel alt text'
					width='647px'
				/>
			</SecaoImage>
			{/* </Row> */}
			<Hr className='divider' />
			<DivDataCom className='calendar'>
				<CalendarDiv>
					<IconH2>
						<div>
							<Icon src={Calendar} />
						</div>
						<DataMobile>{formatDateString(metadata.data)}</DataMobile>
						<Data>{formataDataCosmic(metadata.data)}</Data>
					</IconH2>
				</CalendarDiv>
				<ShareDiv>
					<CompIcon>
						<Compartilhar>
							<Compart>Compartilhar:</Compart>
						</Compartilhar>
						<Icons>
							<IconDiv>
								<a target='_blank' href={facebookBody}>
									<Facebook />
								</a>
							</IconDiv>
							<IconDiv>
								<a target='_blank' href={linkedinBody}>
									<LinkedinIco />
								</a>
							</IconDiv>
							<IconDiv>
								<a target='_blank' href={twitterBody}>
									<Twitter />
								</a>
							</IconDiv>
							<IconDiv>
								<WhatsappWeb href={whatsWebBody} data-action='share/whatsapp/share' target='_blank'>
									<Whatsapp />
								</WhatsappWeb>
								<WhatsappMobile href={whatsMobileBody} data-action='share/whatsapp/share' target='_blank'>
									<Whatsapp />
								</WhatsappMobile>
							</IconDiv>
						</Icons>
					</CompIcon>
				</ShareDiv>
			</DivDataCom>
		</Container>
	)
}
